<template>
  <div>
    <div v-if="videos != ''">
      <div class="row">
        <div :class="col"
             :key="video.ucc_id"
             v-for="video in videos">
          <div class="card">
            <!-- Video -->
            <vue-plyr>
              <video class="video-player"
                     :src="video.url"
                     :style="{height: `${vpHeight}px`}"
                     :poster="video.url">
                <source :src="video.url" type="video/mp4" size="720">
                <source :src="video.url" type="video/mp4" size="1080">
              </video>
            </vue-plyr>

            <div class="container pb-2 pt-2">
              <!-- Approve, Reject, & Report Icon -->
              <div class="">
                <i class="fas fa-flag report-icon"
                   @click="reportVideo(video)"
                   title="Report Video">
                </i>
                <i class="fas fa-times reject-icon"
                   @click="updateStatus(video, 'rejected')"
                   title="Reject Video">
                </i>
                <i class="fas fa-check approve-icon"
                   @click="updateStatus(video, 'approved')"
                   title="Approve Video">
                </i>
              </div>

              <!-- Video Owner Info -->
              <div class="">
                <p class="mb-0 small">
                  {{ video.nickname }}
                </p>
                <p class="mb-0 small">
                  Classroom: {{ video.classroom_name }}
                </p>
                <p class="mb-0 small">
                  {{ video.created | moment("MMMM D, YYYY") }}
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    
    <!-- No Video Request -->
    <div class="row" v-else>
      <div class="col">
        <p class="mt-3 text-center">No video requests</p>
      </div>
    </div>
    <div class="row"
          v-if="videosCurrentPage && videosLastPage &&
           videosCurrentPage < videosLastPage">
      <div class="col text-center">
        <b-button class="btn btn-h3-small btn-h3-blue
                          text-center mt-3 mb-3"
                  type="button"
                  @click="loadMoreVideos">
          Load More
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  const ReportContent = () => import('@/components/modals/ReportContent.vue');

  export default {
    name  : 'VideoRequests',
    props : {
      colSize : Number,
      filter  : Object,
    },
    data() {
      return {
        videos            : [],
        videosPerPage     : 8,
        videosCurrentPage : 0,
        videosLastPage    : null,
        user              : this.$store.getters['user/user'],

        colWidth : 0,
        vpHeight : 0,
      }
    },
    computed : {
      col() {
        return 'col-' + this.colSize;
      },
    },
    watch : {
      filter(value) {
        this.videos = [];
        this.videosCurrentPage = 0;
        if (value)
          this.getFilterResult(value);
        else
          this.getVideoRequests(0);
      },

      colWidth() {
        this.vpHeight = this.colWidth > 0 ? this.colWidth * 0.75 : 0;
      },
    },
    updated() {
      this.getColumnWidth();
    },
    mounted() {
      this.getVideoRequests(0);
      this.getColumnWidth();
    },
    methods : {
        
      /**
       * Get all pending video requests
       * @param currentPage Pagination's current page
       */
      getVideoRequests(currentPage = this.videosCurrentPage) {
        this.$http.get('api/approval_request/videos', {
          params : {
            page     : currentPage + 1,
            perPage  : this.videosPerPage,
            schoolId : this.user.school_id,
          },
        }).then(response => {
          this.videosCurrentPage = response.data.currentPage;
          this.videosLastPage = response.data.lastPage;

          if (this.videosCurrentPage > 1) {
            const arr1 = this.videos;
            const arr2 = response.data.data;

            this.videos = arr1.concat(arr2);
          } else this.videos = response.data.data;
        }).catch(() => {
          this.videos = [];
        });
      },

      /**
       * Approve or Reject Video
       *
       * @param video Video's data
       * @param action Action to make (i.e. 'approved' or 'rejected')
       */
      updateStatus(video, action) {
        this.$http.put('api/approval_request', {
          "status"            : action,
          "userId"            : this.user.user_id,
          "approvalRequestId" : video.approval_request_id,
        }).then(() => {
          // TODO: find other way to refresh video list
          this.getVideoRequests(0); // reset currentPage to 0

          this.$notify({
            group : 'notif',
            title : (action === 'approved') ? 'Approved' : 'Rejected',
            type  : (action === 'approved') ? 'success' : 'warn',
            text  : 'Video from ' + video.nickname,
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            title : 'Failed',
            type  : 'error',
            text  : 'Oops! Something went wrong.',
          });
        });
      },

      /**
       * Get Video Requests per Filter
       *
       * @param filter Selected filter's data
       */
      getFilterResult(filter) {
        this.$http.get('api/approval_request/ucc/filtered', {
          params : {
            type       : 'ucc_video',
            userId     : filter.user_id,
            schoolId   : this.user.school_id,
            educatorId : this.$store.getters['user/user'].user_id,
            page       : this.videosCurrentPage + 1,
            perPage    : this.videosPerPage,
          },
        }).then(response => {
          this.videosCurrentPage = response.data.currentPage;
          this.videosLastPage = response.data.lastPage;

          if (this.videosCurrentPage > 1) {
            const arr1 = this.videos;
            const arr2 = response.data.data;

            this.videos = arr1.concat(arr2);
          } else this.videos = response.data.data;
        });
      },

      /**
       * Get Column's Width
       */
      getColumnWidth() {
        this.$nextTick(() => {
          const $colId = this.$el.getElementsByClassName('video-player')[0];
          if ($colId)
            this.colWidth = $colId.clientWidth;
        });
      },

      /**
       * Report Image
       * @param video
       */
      reportVideo(video) {
        this.$modal.show(ReportContent, {
          mediaData : video,
          type      : 'video',
        }, {
          height   : 'auto',
          adaptive : true,
        });
      },

      /**
       * Load More
       */
      loadMoreVideos() {
        if (this.filter)
          this.getFilterResult(this.filter)
        else
          this.getVideoRequests();
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/moderator/video-request";
</style>
